import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
        <div className="section section-padding case-study-featured-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                            <img src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"} alt="travel"/>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                            <div className="section-heading heading-left">
                                <span className="subtitle">Who we are</span>
                                <h2 className="title">Building software for world changers</h2>
                                <p>At Blue Fifteen, we believe that software has the power to shape the future. That's
                                    why we design and develop digital solutions that don't just meet current needs, but
                                    also have the potential to change the world. </p>
                                <p>Our team harnesses the latest
                                    technologies, creativity, and strategic thinking to build innovative software that
                                    breaks boundaries, fosters progress, and creates positive impacts on businesses and
                                    communities alike. With Blue Fifteen, expect software solutions that inspire change
                                    and drive the world forward.</p>
                                {/*<Link to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link>*/}
                            </div>
                            <div className="case-study-counterup">
                                <div className="single-counterup">
                                    <h2 className="count-number">
                                        <TrackVisibility once>
                                            {({isVisible}) => (
                                                <span className="number count">
                                        {isVisible ? <CountUp end="5" duration={1}/> : null}
                                    </span>
                                            )}
                                        </TrackVisibility>
                                        <span className="symbol">+</span>
                                    </h2>
                                    <span className="counter-title">Years on the market</span>
                                </div>
                                <div className="single-counterup">
                                    <h2 className="count-number">
                                        <TrackVisibility once>
                                            {({isVisible}) => (
                                                <span className="number count">
                                        {isVisible ? <CountUp end="100" duration={1}/> : null}
                                    </span>
                                            )}
                                        </TrackVisibility>
                                        <span className="symbol">+</span>
                                    </h2>
                                    <span className="counter-title">Projects delivered so far</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;