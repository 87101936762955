import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import { Link } from 'react-router-dom';

const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BreadCrumbOne
                    title="Terms Of Use"
                    page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <p>Welcome to Blue Fifteen. These terms of use ("Terms") govern your use of our
                                        website ("Site"). Please read these Terms carefully, and contact us if you have
                                        any questions. By using our Site, you agree to be bound by these Terms.</p>

                                    <h4>Use of Site</h4>

                                    <p>Blue Fifteen grants you a limited license to access and make personal use of the
                                        Site and not to download or modify it, or any portion of it, except with express
                                        written consent of Blue Fifteen.</p>

                                    <h4>Content</h4>

                                    <p>All content included on this Site, such as text, graphics, logos, images, is the
                                        property of Blue Fifteen and protected by international copyright laws.</p>

                                    <h4>Privacy</h4>

                                    <p>Please review our <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>, which also governs your visit to our Site, to
                                        understand our practices.</p>

                                    <h4>Electronic Communications</h4>

                                    <p>When you visit our Site or send e-mails to us, you are communicating with us
                                        electronically and consent to receive communications from us electronically.</p>

                                    <h4>Site Policies, Modification, and Severability</h4>

                                    <p>Please review our other policies posted on this Site. These policies also govern
                                        your visit to the Site. We reserve the right to make changes to our Site,
                                        policies, and these Terms at any time. If any of these conditions shall be
                                        deemed invalid, void, or for any reason unenforceable, that condition shall be
                                        deemed severable and shall not affect the validity and enforceability of any
                                        remaining condition.</p>

                                    <h4>Questions</h4>

                                    <p>Questions regarding our Terms of Use, Privacy Policy, or other policy related
                                        material can be directed to our support staff by clicking on the "Contact Us"
                                        link or you can email us at: info@blu15.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default TermsOfUse;