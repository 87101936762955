import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Blue Fifteen | {title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Welcome to Blue Fifteen, your trusted partner for innovative Drupal web design and bespoke development. We specialize in digital marketing strategies that drive growth and elevate your business. Explore our services today!" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;