import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy"/>
            <ColorSwitcher/>
            <main className="main-wrapper">
                <HeaderOne/>
                <BreadCrumbOne
                    title="Privacy Policy"
                    page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">This Privacy policy was last updated on September 10th,
                                            2022.</h5>
                                    </div>
                                    <p>Welcome to Blue Fifteen's privacy policy. This policy sets out how we will use and protect the information that you give us when you use this website. We are committed to ensuring that your privacy is protected.</p>

                                    <h4>Information We Collect</h4>

                                    <p>We may collect the following information:</p>

                                    <ul>
                                        <li>Name</li>
                                        <li>Email</li>
                                        <li>Phone</li>
                                    </ul>
                                    <h4>How We Use the Information</h4>

                                    <p>We use this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>

                                    <h4>Internal record keeping</h4>
                                    <p>We may use the information to improve our products and services</p>
                                    <p>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided</p>
                                    <h4>Cookies</h4>

                                    <p>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site.</p>

                                    <h4>Security</h4>

                                    <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>

                                    <h4>GDPR Compliance</h4>

                                    <p>Blue Fifteen is compliant with the General Data Protection Regulation (GDPR). We only collect personal data with your consent and for a specific purpose. You have the right to request access to your personal data, or ask for your data to be erased at any time. We are committed to ensuring that your information is secure and kept confidential.</p>

                                    <h4>Changes to Our Privacy Policy</h4>

                                    <p>Blue Fifteen reserves the right to modify or update this privacy policy at any time without prior notice. Any changes to this privacy policy will be posted on this webpage.</p>

                                    <p>By using our website, you consent to the collection and use of your information as outlined in this Privacy Policy.</p>

                                    <h4>Contact Us</h4>

                                    <p>If you have any questions about this privacy policy or your personal data, please contact us at info@blu15.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default PrivacyPolicy;