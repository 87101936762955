import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">We do design, code &amp; develop.</h2>
                                <p>Our approach to design, coding, and development is rooted in
                                collaboration, innovation, and meticulous attention to detail.</p>

                                <p><strong>Design</strong>: We kick off the design process with in-depth discovery sessions, learning about
                                your brand, audience, and objectives. Our creative team then translates these insights
                                into visually stunning designs that tell your story and engage your audience. We
                                prioritize usability, ensuring every design element enhances the user experience.</p>

                                <p><strong>Code</strong>: Our expert developers transform design mockups into functional, interactive
                                digital solutions. We write clean, optimized code that ensures your website or
                                application is responsive, scalable, and performant. Whether we're working in Drupal,
                                Headless Drupal, React, or Next.js, we adhere to best practices and maintain a
                                forward-thinking approach.</p>

                                <p><strong>Develop</strong>: Our development process goes beyond just coding. We work iteratively, building
                                features and functionalities that align with your business needs and objectives. Every
                                solution we develop undergoes rigorous testing, guaranteeing seamless operation and peak
                                performance. Once your product is live, we offer continuous support and enhancement
                                services, ensuring your digital assets stay current, competitive, and secure.</p>

                                <p>With Blue Fifteen, every step in the design, coding, and development process is handled
                                with utmost precision and care. Our integrated approach ensures your project is handled
                                efficiently, professionally, and with your ultimate goals in mind.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free Blue Fifteen quote now</h3>
                            <FormOne/>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
                                                   alt="Bubble"/></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
                                                   alt="line"/></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
                                                   alt="line"/></li>
            </ul>
        </section>
    )
}

export default AboutOne;